// src/utils/auth.ts
import { jwtDecode } from "jwt-decode";
import moment from "moment";

export const isTokenExpired = (token) => {
  if (!token) return true;

  const decodedToken = jwtDecode(token);
  const currentTime = Math.floor(Date.now() / 1000);

  return decodedToken.exp ? decodedToken.exp < currentTime : true;
};
