import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "../../provider/AuthProvider";
import { useEffect } from "react";

const AuthLayout = () => {
  const { accessToken, user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      if (user?.userType === "admin") {
        navigate("/admin/login");
      } else if (user?.userType === "superAdmin") {
        navigate("/login");
      }
    }
  }, [accessToken, navigate, user]); // Dependency array includes accessToken and navigate

  return <Outlet />;
};

export default AuthLayout;
