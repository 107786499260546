import { configureStore, createSlice } from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import rightDrawerSlice from "../features/common/rightDrawerSlice";
import leadsSlice from "../features/leads/leadSlice";
import productSlice from "../features/common/product/productSlice";
import multipleChoiceSlice from "../features/common/multipleChoice/multipleChoiceSlice";
import essaySlice from "../features/common/essay/essaySlice";
import assessmentSlice from "../features/common/assessment/assessmentSlice";

const unsavedChangesSlice = createSlice({
  name: "unsavedChanges",
  initialState: {
    isDirty: false,
  },
  reducers: {
    setIsDirty: (state, action) => {
      state.isDirty = action.payload;
    },
  },
});

export const { setIsDirty } = unsavedChangesSlice.actions;

const combinedReducer = {
  header: headerSlice,
  rightDrawer: rightDrawerSlice,
  modal: modalSlice,
  lead: leadsSlice,
  product: productSlice,
  multipleChoice: multipleChoiceSlice,
  essay: essaySlice,
  assessment: assessmentSlice,
  unsavedChanges: unsavedChangesSlice.reducer,
};

const store = configureStore({
  reducer: combinedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action paths for the serializable check
        ignoredActions: [
          "product/setProductState",
          "createProduct/setProductState",
        ],
        ignoredPaths: ["product.headPhoto"],
      },
    }),
});

store.subscribe(() => {
  console.log("Store changes", store.getState());
});

export default store;
