import { createSlice } from "@reduxjs/toolkit";

export const assessmentSlice = createSlice({
  name: "assessment",
  initialState: [
    {
      answerId: "",
      question: "",
      studentAnswer: "",
      correctAnswer: "",
      score: 0,
      maxScore: 0,
    },
  ],
  reducers: {
    setAssessmentScore: (state, action) => {
      const { index, value } = action.payload;
      state[index].score = value;
    },
    setWholeAssessmentState: (state, action) => {
      const { data } = action.payload;
      const assessmentState = data.AnswerESs.map((item) => {
        const {
          id: answerId,
          answer: studentAnswer,
          answerScore: score,
          QuestionES,
        } = item;
        return {
          answerId,
          studentAnswer,
          score,
          question: QuestionES?.question,
          correctAnswer: QuestionES?.correctAnswer,
          maxScore: QuestionES?.score,
        };
      });
      return assessmentState;
    },
  },
});

export const { setAssessmentScore, setWholeAssessmentState } =
  assessmentSlice.actions;

export default assessmentSlice.reducer;
