import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  id: 0,
  productName: "",
  categoryName: "",
  minScore: 0,
  minMultipleChoiceScore: 0,
  minLiveCodeScore: 0,
  headTitle: "",
  description: "",
  note: "",
  examDuration: 0,
  headPhoto: "",
  certificationLevel: "",
  language: "",
  testRules: "",
  promotionOverview: "",
  benefit: "",
  daysBeforeExpiry: 0,
  certificationEligibility: "",
  certificationDetails: "",
  certificationOutline: "",
  certificationRequirements: "",
  certificationCurriculum: [
    {
      title: "",
      text: "",
    },
  ],
  price: 0,
  discount: 0,
  discountStartDate: "",
  discountEndDate: "",
  discountStartTime: "",
  discountEndTime: "",
  // authorName: '',
  // authorPosition: '',
  // authorSignature: '',
  authorIds: [],
  signerId: 0,
  typeTreshold: 0,
  isPublished: false,
  hasMultipleChoice: false,
  countMultipleChoiceQuestions: 0,
  hasEssay: false,
  countEssayQuestions: 0,
  hasLiveCode: false,
  countLiveCodeQuestions: 0,
};

export const productSlice = createSlice({
  name: "createProduct",
  initialState: {
    ...initialState,
  },
  reducers: {
    setProductState: (state, action) => {
      const { name, value, stateKey, index } = action.payload;
      if (stateKey === "certificationCurriculum") {
        state[stateKey][index][name] = value;
      } else {
        state[name] = value;
      }
    },
    setWholeProductState: (state, action) => {
      const { data } = action.payload;

      const { discountStart, discountEnd } = data;
      const editData = {
        ...data,
        discountStartDate: {
          startDate: moment(discountStart).format("YYYY-MM-DD"),
          endDate: moment(discountStart).format("YYYY-MM-DD"),
        },
        discountEndDate: {
          startDate: moment(discountEnd).format("YYYY-MM-DD"),
          endDate: moment(discountEnd).format("YYYY-MM-DD"),
        },
        discountStartTime: moment(discountStart).format("HH:mm"),
        discountEndTime: moment(discountEnd).format("HH:mm"),
      };
      return editData;
    },
    addCertificationCurriculum: (state, action) => {
      state.certificationCurriculum.push({ title: "", text: "" });
    },
    removeCertificationCurriculum: (state, action) => {
      const { index } = action.payload;
      state.certificationCurriculum.splice(index, 1);
    },
    resetProductState: () => {
      return initialState;
    },
    setAuthorIds: (state, action) => {
      state.authorIds = action.payload.value;
    },
    setSignerId: (state, action) => {
      state.signerId = action.payload.value;
    },
    setIsPublished: (state, action) => {
      console.log(action.payload);
      state.isPublished = action.payload;
    },
  },
});

export const {
  setProductState,
  setWholeProductState,
  addCertificationCurriculum,
  removeCertificationCurriculum,
  resetProductState,
  setAuthorIds,
  setSignerId,
  setIsPublished,
} = productSlice.actions;

export default productSlice.reducer;
