import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "./../../provider/AuthProvider"; // Ensure the path is correct

const PrivateLayout = () => {
  const { accessToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accessToken) {
      if (window.location.pathname.includes("admin")) {
        navigate("/admin/login");
      } else {
        navigate("/login");
      }
    }
  }, [accessToken, navigate]); // Dependency array includes accessToken and navigate

  return <Outlet />;
};

export default PrivateLayout;
